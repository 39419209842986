import styled from "@emotion/styled";
export const StyledProductSignUp = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;

  .product-logo-image {
    display: flex;
    align-items: center;

    .picture-component {
      img {
        width: 160px;
      }
    }
  }
`;
