import styled from "@emotion/styled";
export const StyledProductSignupButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  &.product-signup-wrapper {
    .product-sign-up {
      justify-content: center;
      height: fit-content;

      .signup-button {
        height: 35px;
      }
    }
  }
`;
