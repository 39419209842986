import styled from "@emotion/styled";
import { TOP_BAR } from "constants/z-index";
export const StyledMobileBackgroundImageCard = styled.div`
  position: relative;
  width: 100vw;
  padding: 0 ${({ horizontalPadding })=>horizontalPadding || 0};
  border-radius: 12px;

  .details-wrapper {
    top: 0;
    width: calc(100vw - ${({ horizontalPadding })=>horizontalPadding || 0} * 2);
    position: absolute;
    z-index: ${TOP_BAR};
    display: flex;
    flex-direction: column;
    align-items: center;

    .card-button-component {
      width: fit-content;

      .product-signup-wrapper {
        flex-direction: column;
        align-content: center;
        width: fit-content;
        display: flex;
        align-items: center;

        .product-sign-up {
          .signup-button {
            height: fit-content;
          }
        }
      }
    }
  }

  .mobile-card-image {
    img {
      width: 100%;
      object-fit: cover;
      position: relative;
      border-radius: 12px;
    }
  }
`;
