import styled from "@emotion/styled";
import { FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH, MOBILE_QUERY_MAX_WIDTH, SM, MD } from "constants/vp-sizes";
export const StyledBackgroundImageCardsGridComponent = styled.div`
  max-width: ${FULL_HD_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px;
  margin: auto;
  min-width: ${SM}px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ cardsGap })=>cardsGap || "12px"};

  @media (max-width: ${MD}px) {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    min-width: unset;
  }
`;
