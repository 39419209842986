import styled from "@emotion/styled";
import Colors from "styles/colors";
export const StyledLabel = styled.div`
  border: ${({ borderColor })=>`1px solid ${Colors[borderColor]}`};
  color: ${({ borderColor })=>Colors[borderColor]};
  padding: 4px 8px;
  margin: auto;
  border-radius: 4px;
  width: fit-content;
  font-size: 0.875rem;
`;
