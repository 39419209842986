import styled from "@emotion/styled";
import { LAYER_ONE, LAYER_TWO, TOP_BAR } from "constants/z-index";
import { LG, MD, MID_HEADER_DESKTOP_MENU } from "constants/vp-sizes";
const getCardHeightStyle = (defaultHeight, factor = 1)=>defaultHeight ? `calc(${defaultHeight} * ${factor})` : "auto";
export const StyledBackgroundImageCard = styled.div`
  position: relative;
  overflow: hidden;
  height: ${({ cardHeight })=>cardHeight || "auto"};
  margin-right: ${({ marginRight })=>marginRight || "0"};
  margin-left: ${({ marginLeft })=>marginLeft || "0"};
  margin-top: ${({ marginTop })=>marginTop || "0"};
  margin-bottom: ${({ marginBottom })=>marginBottom || "0"};
  border-radius: ${({ borderRadius })=>borderRadius || "0"};

  .paragraph-wrapper {
    position: relative;
    z-index: ${TOP_BAR};
  }

  &.active {
    .card-image {
      opacity: 0;
    }

    .background-video {
      opacity: 1;
    }
  }

  &:not(.active) {
    .background-video {
      transition: opacity 0.5s ease-in-out;
    }
  }

  .card-image {
    position: absolute;
    z-index: ${LAYER_ONE};
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${({ cardHeight })=>`auto ${cardHeight}`};
    background-image: ${({ backgroundImage })=>`url(${backgroundImage})`};
  }

  .background-video {
    height: ${({ cardHeight })=>cardHeight || "auto"};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    z-index: ${LAYER_TWO};
    opacity: 0;

    video {
      height: ${({ cardHeight })=>cardHeight || "auto"};
      object-fit: contain;
    }
  }

  @media (max-width: ${LG}px) and (min-width: ${MID_HEADER_DESKTOP_MENU}px) {
    height: ${({ cardHeight })=>getCardHeightStyle(cardHeight, 0.82)};

    .background-video {
      height: ${({ cardHeight })=>getCardHeightStyle(cardHeight, 0.82)};

      video {
        height: ${({ cardHeight })=>getCardHeightStyle(cardHeight, 0.82)};
      }
    }

    .card-image {
      background-size: ${({ cardHeight })=>`auto ${getCardHeightStyle(cardHeight, 0.82)}`};
    }

    .title-wrapper {
      margin-bottom: 8px;
    }
  }

  @media (max-width: ${MID_HEADER_DESKTOP_MENU}px) and (min-width: ${MD}px) {
    height: ${({ cardHeight })=>getCardHeightStyle(cardHeight, 0.78)};

    .background-video {
      height: ${({ cardHeight })=>getCardHeightStyle(cardHeight, 0.78)};

      video {
        height: ${({ cardHeight })=>getCardHeightStyle(cardHeight, 0.78)};
      }
    }

    .card-image {
      background-size: ${({ cardHeight })=>`auto ${getCardHeightStyle(cardHeight, 0.78)}`};
    }

    .title-wrapper {
      margin-bottom: 4px;

      .card-title {
        font-size: 2rem;
      }
    }

    .paragraph-wrapper .paragraph-body {
      font-size: 1rem;
      line-height: 24px;
    }
  }

  @media (max-width: ${MD}px) {
    margin: unset;
  }
`;
